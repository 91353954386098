export const allMixin = {
	data() {
		return {
      styleClass: ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.setHeaderStyle()
    },300)
  },
  methods: {
    setHeaderStyle() { //在滑动出banner图以后给header设置为白底
      if(this.$refs.banner) {
        let height = this.$refs.banner.offsetHeight
        if(height) {
          this.clientRectAddWhite()
          window.addEventListener('scroll', () => {
            let scroll = document.documentElement.scrollTop || document.body.scrollTop
            if(scroll >= height) {
              this.styleClass = 'white'
            } else {
              this.styleClass = ''
            }
          })
        }
      }

    },
    clientRectAddWhite() { //页面刚加载时，如果banner不在视窗内，则认为已经划过banner了，添加white
      var {bottom} = this.$refs.banner.getBoundingClientRect()
      if(bottom <= 0) {
        this.styleClass = 'white'
      }
    },
    gotoSubscribe(){ //跳转到演示页面
      this.$router.push('subscribe')
    },
    goPage(path) {
      // console.log(path)
      this.$router.push(path)
    }
  }
}
