export const aboutUsZH = {
  content: `香港长城投资商学院成立以来，始终秉承“教育服务社会发展”的办学理念，用“国际教育”传递思维价值；
  秉承“资本服务产业发展”的投资理念，以“长城基金”助力企业成长；
  秉承“国际化思维服务企业国际化发展”的产业服务理念，以“国际服务”带动产业国际化发展。`
}

export const aboutUsEN = {
  content: `Great Wall Fund has a diversified portfolio of investors, including government, global venture capital head financial institutions, internationally renowned enterprises and entrepreneurs

  The composition of investors in the primary and secondary markets. Great Wall Fund focuses on the outstanding resources of Great Wall Investment Business School,
  
  Actively participate in and use capital to assist innovative and high-growth start-ups and entrepreneurs from Great Wall Investment Business School and universities.`
}