import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [{
      path: '/',
      name: 'Index',
      component: () => import('@/components/views/index/index')
    },
    {
      path: '/index',
      name: 'Index',
      component: () => import('@/components/views/index/index')
    },
    {
      path: '/ccInvestment',
      name: 'ccInvestment',
      component: () => import('@/components/views/ccInvestment/index')
    },{
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('@/components/views/aboutUs/index')
    },
    {
      path: '/ccEducation',
      name: 'ccEducation',
      component: () => import('@/components/views/ccEducation/index')
    },{
      path: '/internationalSummit',
      name: 'internationalSummit',
      component: () => import('@/components/views/internationalSummit/index')
    },
    {
      path: '/newsCenter',
      name: 'newsCenter',
      component: () => import('@/components/views/newsCenter/index')
    },{
      path: '/newsDetail',
      name: 'newsDetail',
      component: () => import('@/components/views/newsCenter/newsDetail')
    },{
      path: '/certificateQuery',
      name: 'certificateQuery',
      component: () => import('@/components/views/certificateQuery/index')
    },
    
  ],
  scrollBehavior () {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  }
})
