export const firstPageZH = {
  aboutWallTitle: '关于长城',
  aboutWallTitleDes1: `香港长城投资商学院成立以来，始终秉承“教育服务社会发展”的办学理念，用“国际教育”传递思维价值；秉承“资本服务产业发展”的投资理念，以“长城基金”助力企业成长；秉承“国际化思维服务企业国际化发展”的产业服务理念，以“国际服务”带动产业国际化发展。`,
  aboutWallTitleDes2: `荏苒十载兴未减，在深圳这片朝气蓬勃的土地上，长城投资商学院在人工智能、互联网、新能源、新材料、航空航天、生物制药、大健康等战略新兴产业，先后累计举办超过上千场融资培训、项目路演会、高端峰会、圆桌论坛等活动。`,
  GreatWallFundTxt1: `长城基金拥有多元化投资人阵容，由政府、全球风险投资头部金融机构、国际知名企业及企业家个人、一二级市场投资人组成。长城基金围绕长城投资商学院卓越的资源，积极参与并以资本助力来自长城投资商学院及高校的拥有创新、高成长型的初创型企业及创业者。`,
  GreatWallFundTxt2: `长城基金以粤港澳大湾区为核心，链接境内外投资资源，构建多元化企业赋能体系。拥有深厚的资源、远见以及经验，可以多种不同的方式帮助企业发展；在扩大圈层的同时覆盖更多优秀的创业者和项目作为项目储备。`,
  orientation: `投资于新一代技术领域、国家扶持的新兴行业，聚焦拥有较高技术门槛和市场壁垒的企业。`
}

export const firstPageEN = {
  aboutWallTitle: 'About Great Wall',
  aboutWallTitleDes1: `Since its establishment, Hong Kong Great Wall Investment Business School has always adhered to the educational philosophy of "education serves social development", and used "international education" to convey the value of thinking; Adhere to the investment concept of "capital service industry development", and use "Great Wall Fund" to help enterprises grow; Adhering to the industrial service concept of "international thinking service for the international development of enterprises".`,
  aboutWallTitleDes2: `After ten years of growth, the Great Wall Investment Business School has held more than 1000 financing training, project roadshows, high-end summits, round-table forums and other activities in strategic emerging industries such as artificial intelligence, the Internet, new energy, new materials, aerospace, biopharmaceuticals, and big health.`,
  GreatWallFundTxt1: 'Great Wall Fund has a diversified portfolio of investors, consisting of government, global venture capital leading financial institutions, internationally renowned enterprises and individual entrepreneurs, and investors in primary and secondary markets. Centering on the outstanding resources of Great Wall Investment Business School, Great Wall Fund actively participates in and assists innovative, high-growth start-ups and entrepreneurs from Great Wall Investment Business School and universities with capital.',
  GreatWallFundTxt2: 'The Great Wall Fund takes the Greater Bay Area of Guangdong, Hong Kong, and Macao as its core, linking domestic and foreign investment resources, and constructing a diversified enterprise empowerment system. With profound resources, foresight, and experience, we can help the development of the enterprise in various ways; Expand the circle while covering more outstanding entrepreneurs and projects as project reserves.',
  orientation: `Invest in new generation technology fields and emerging industries supported by the state, focusing on enterprises with high technology barriers and market barriers.`
}