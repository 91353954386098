<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  overflow: hidden;
}
*{
  margin: 0;
  padding: 0;
}
ul,li{
  list-style: none;
}
.redBtn{
  position: relative;
  background: #DA3041;
}
.redBtn:hover::after{
  position: absolute;
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.12);
}
.whiteBtn:hover{
  background: #fff !important;
  border-radius: 2px;
  color: #DA3041 !important;
  cursor: pointer;
}
#app{
  min-width: 1320px;
}
.module-bg{
  width: 100%;
  display: block;
}
.auto-width{
  min-width: 1320px;
  /* width: 1320px; */
  margin: 0 auto;
  box-sizing: border-box;
}
.qy-title{
      font-size: 46px;
      color: #2B2D30;
      line-height: 65px;
    }
    .yw-h5{
      font-size: 24px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #A9AFB9;
      line-height: 33px;
      letter-spacing: 8px;
      margin-top: 20px;
    }
/* @media screen and (min-width: 1200px) and (max-width: 1440px) {
  .auto-width{
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .auto-width{
    width: 1200px;
  }
} */
.con-title{
            font-weight: 400;
            color: #2B2D30;
            line-height: 65px;
            font-size: 46px;
            text-align: center;
        }
.title-yw{
            width: 600px;
            margin: 19px auto 62px;
            display: flex;

        }
        .l-line{
            margin-top: 16px;
            flex-basis: 140px;
            width: 140px;
            height: 1px;
            border-top: 1px solid ;
            border-image:  linear-gradient(90deg, rgba(85, 87, 92, 0),  #A9AFB9) 1 1;
        }
        .r-line{
            margin-top: 16px;
            flex-basis: 140px;
            width: 140px;
            height: 1px;
            border-top: 1px solid #F4F5F9;
            border-image: linear-gradient(270deg, rgba(85, 87, 92, 0),  #A9AFB9) 1 1;
        }
        .t-yw{
            flex: 1;
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #A9AFB9;
            line-height: 33px;
            letter-spacing: 8px;
            text-align: center;
        }
</style>
