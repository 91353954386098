import axios from 'axios';
import {Message} from 'element-ui'

var api = axios.create({
    timeout: 5000,
    Headers: {'Content-Type': 'application/json'}
});

let root = process.env.VUE_APP_BASE_API;

api.interceptors.request.use(config => {
    config.url = root + config.url;
    config.headers['languageType'] =window.sessionStorage.getItem('languageType') || 'zh-CN'; // 'en-US';//en-US  zh-CN
    return config
})
api.interceptors.response.use(response => {
    if (+response.data.code !== 200) {
        Message({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: response.data.msg || '接口异常'
        })
        return Promise.reject(response.data)
    }
    return response.data
},
error => {
  const msg = error.toString()
  const url = error.request.responseURL
  Message({ type: 'error', message: `${msg} ${url}`, dangerouslyUseHTMLString: true })
  return Promise.reject(error)
})

export default api;
