import {firstPageZH} from './modules/firstPage'
import {aboutUsZH} from './modules/aboutUs'
export const main={
    firstPageContent: firstPageZH,
    aboutUsContent: aboutUsZH,
    HomePage:'首页',
    AboutUs:'关于我们',//关于我们
    GreatWallEducation:'长城教育',//长城教育
    GreatWallInvestment:'长城投资', //长城投资
    InternationalSummit:'国际峰会',//国际峰会
    NewsCenter:'新闻中心',//新闻中心
    CertificateQuery:'证书查询' ,// 证书查询

    AboutGreatWall:'关于长城',
    GreatWallFund:'长城基金',
    DualTrackEducation:'双轨教育',
    QualityCourseRecommendation:'精品课程推荐',
    InternationalInvestmentAndFinancingServices:'国际投融服务',
    CooperativePartner:'合作伙伴',

    BrandStory:'品牌故事',
    Subsidiaries:'旗下公司',
    CollegeLeaders:'学院领导',
    MentorTeam:'导师团队',
    ContactUs:'联系我们',

    ThreeMajorCourses:'三大课程板块',
    CourseFeatures:'课程特色',

    OurAdvantages:'我们的优势',
    SuccessCases:'成功案例',
    InvestmentAndFinancingProcess:'投融流程',
    InvestmentAndFinancingServices:'投融服务',

    SummitIntroduction:'峰会简介',
    SponsorIntroduction:'主办方介绍',
    GuestsPresent:'出席嘉宾',
    AttendingInstitutions:'出席机构',

    HotNews:'热点资讯',
    UpcomingClasses:'课程预告',

    footerAddress: '深圳市福田区莲花路2005号新世界文博中心1406',
    footerTelePhone: '电话',
    footerCopyright: '长城投资商学院版权所有',
    footerOffice: '公众号',
    footerHelper: '小助手'
}













